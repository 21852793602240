import { prop } from '@/helpers'
import { DepositMethod } from '../DepositMethod'

export const getCurrency = prop(DepositMethod.currency)
export const isDeposit = prop(DepositMethod.isDeposit)
export const getLogo = prop(DepositMethod.logo)
export const getMinAmountLimit = prop(DepositMethod.minAmountLimit)
export const getMaxAmountLimit = prop(DepositMethod.maxAmountLimit)
export const getPaymentMethodId = prop(DepositMethod.paymentMethodId)
export const getPaymentMethodName = prop(DepositMethod.paymentMethodName)
export const getProviderName = prop(DepositMethod.providerName)
export const getTransactionTypeId = prop(DepositMethod.transactionTypeId)
